import React from "react"
import styled from "styled-components"
import Layout from "../layout/layout"
import Fade from 'react-reveal/Fade';
import { graphql } from "gatsby"

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5vw;
  margin-bottom: 60px;
  text-align: center;
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: left;

  h2 {
    color: ${({ theme }) => theme.primaryColor};
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    img {
      width: 100%;
    }
  }
`;

const PostInfo = styled.div`
  margin-top: 10px;
  font-size: 16px;
  color: gray;
`;

export default function BlogPost({ data }) {
  const post = data.allWpPost.edges[0].node;

  return (
    <Layout
      pageTitle={`${post.title} - Eugenio Maucieri`}
      pageDescription="Miglior studio dentistico"
      pageKeywords="Salute, Denti, Chirurgia"
      pageAuthor="Eugenio Maucieri"
    >
      <PageWrapper>
        <Fade left duration={1000}>
          <h1 style={{marginTop: "70px"}}>{post.title}</h1>
        </Fade>

        {/* Aggiungi la data di pubblicazione */}
        <PostInfo>Pubblicato il: {post.date}</PostInfo>

        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: post.content }} />
        </ContentWrapper>
      </PageWrapper>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          title
          slug
          content
          date(formatString: "MM/DD/YYYY")
          featuredImage {
            node {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`;